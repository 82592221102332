/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * 登录时，用户名不能为空
 * @param str
 * @returns {Boolean}
 */
export function validLoginUsername(str) {
  const name = str.trim()
  return name.length > 0
  // const valid_map = ['admin', 'editor']
  // return valid_map.indexOf(str.trim()) >= 0
}

/**
 * 登录时，密码不能为空
 * @param str
 * @returns {boolean}
 */
export function validLoginPwd(str) {
  const pwd = str.trim()
  return pwd.length > 0
}

/**
 * 登录时，验证码表单验证；不能为空，且限定为数字
 * @param {*} str
 * @returns
 */
export function validVerifyCode(str) {
  const code = str.trim()
  if (code.length < 1) {
    return new Error('验证码不能为空！')
  }
}