import axios from 'axios'
// import store from '@/store'
import {Notify} from 'vant'
import {getToken} from '@/utils/auth'
import store from "@/store";

const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 5000,
});

service.interceptors.request.use(
    config => {
        // do something before request is sent

        if (getToken()) {
            config.headers['chen-sa-token'] = getToken()
        }
        return config
    },
    error => {
        // do something with request error
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    response => {
        const res = response.data

        //后端返回不是200，打印错误信息
        if (res.code !== 200) {

            // token失效等情况
            if (res.code === 500) {
                store.dispatch('user/resetToken').then(() => {
                    location.reload()
                })
            }

            // Notify({type: 'danger', message: res.msg || 'token失效请重新登录', duration: 3000});
            // if (res.code === 'SN005' || res.code === 'SN009') {
            //     // to re-login
            //     MessageBox.confirm('您已注销，可以取消以停留在此页面，或重新登录。', 'Confirm logout', {
            //         confirmButtonText: 'Re-Login',
            //         cancelButtonText: 'Cancel',
            //         type: 'warning'
            //     }).then(() => {
            //         store.dispatch('user/resetToken').then(() => {
            //             location.reload()
            //         })
            //     })
            // }
            return Promise.reject(new Error(res.msg || 'Error'))
        } else {
            return res
        }
    },
    error => {
        if (error && error.response) {
            switch (error.response.status) {
                case 404:
                    Notify({type: 'warning', message: '404错误，页面不见啦～', duration: 3000});
                    break
                case 500:
                    Notify({type: 'danger', message: '500错误，管理员正火速赶来～', duration: 3000});
                    break
                default:
                    Notify({type: 'warning', message: '连接出了错，找找原因～', duration: 3000});
            }
        }
        // return Promise.reject(error) // 返回这个一运行项目有时会各种报错
        return new Error('错误')
    }
)

export default service