import Cookies from 'js-cookie'

const TokenKey = 'user_token'
const GuidKey = 'user_guid'
const UserName = 'user_name'
const Avatar = 'user_avatar'
const Permission = 'user_permission'

/**
 *
 * @returns
 */
export function getToken() {
  return Cookies.get(TokenKey)
}

/**
 *
 * @param {*} token
 * @returns
 */
export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

/**
 *
 * @returns
 */
export function removeToken() {
  return Cookies.remove(TokenKey)
}

/**
 *
 * @returns
 */
export function getGuid() {
  return Cookies.get(GuidKey)
}

/**
 *
 * @param {*} guid
 * @returns
 */
export function setGuid(guid) {
  return Cookies.set(GuidKey, guid)
}

/**
 *
 * @returns
 */
export function removeGuid() {
  return Cookies.remove(GuidKey)
}

/**
 *
 * @returns
 */
export function getUserName() {
  return Cookies.get(UserName)
}

/**
 *
 * @param {*} userName
 * @returns
 */
export function setUserName(userName) {
  return Cookies.set(UserName, userName)
}

/**
 *
 * @returns
 */
export function removeUserName() {
  return Cookies.remove(UserName)
}

/**
 *
 * @returns
 */
export function getAvatar() {
  return Cookies.get(Avatar)
}

/**
 *
 * @param {*} avatar
 * @returns
 */
export function setAvatar(avatar) {
  return Cookies.set(Avatar, avatar)
}

/**
 *
 * @returns
 */
export function removeAvatar() {
  return Cookies.remove(Avatar)
}

/**
 *
 * @returns
 */
export function getPermission() {
  return Cookies.get(Permission)
}

/**
 *
 * @param {*} permission
 * @returns
 */
export function setPermission(permission) {
  return Cookies.set(Permission, permission)
}

/**
 *
 * @returns
 */
export function removePermission() {
  return Cookies.remove(Permission)
}



