/**
 * 用户相关的接口写在这里
 */
import request from '@/utils/request'
/**
* 用户登录
* @param data
* @returns {AxiosPromise}
*/
export function login(data) {
  const url = '/user/login'
  return request({
    url: url,
    method: 'post',
    data
  })
}

/**
* 登录前获取信息
* @param data
* @returns {AxiosPromise}
*/
export function getInfo(data) {
  const url = '/user/info'
  return request({
    url: url,
    method: 'post',
    data
  })
}

/**
 * 获取用户列表
 * @param data
 * @returns {AxiosPromise}
 * @author chenzewei
 */
export function getUserList(data) {
  const url = '/user/list'
  return request({
    url,
    method: 'get',
    data
  })
}

/**
 * 更改用户状态
 * @param data
 * @returns {AxiosPromise}
 * @author chenzewei
 */
export function setStatus(data) {
  const url = '/user/update/status'
  return request({
    url,
    method: 'post',
    data
  })
}

/**
 * 删除用户
 * @param data
 * @returns {AxiosPromise}
 * @author chenzewei
 */
export function deleteUser(data) {
  const url = '/user/delete'
  return request({
    url,
    method: 'post',
    data
  })
}

/**
* 用户登出
* @param data
* @returns {AxiosPromise}
*/
export function logout(data) {
  const url = '/user/logout'
  return request({
    url: url,
    method: 'post',
    data
  })
}

/**
* 新增用户账号
*
*/
export function addUser(data) {
  const url = '/user/add'
  return request(
    {
      url: url,
      method: 'post',
      data
    }
  )
}

/**
* 获取oss签名
*
*/
export function getOss(data) {
  const url = '/oss'
  return request(
    {
      url: url,
      method: 'post',
      data
    }
  )
}

/**
* 编辑用户信息
*/
export function updateUserInfo(data) {
  const url = '/user/update/info'
  return request(
    {
      url: url,
      method: 'post',
      data
    }
  )
}

/**
 * 获取所有角色列表
 */
export function getRolesList(data) {
  const url = '/role/list'
  return request(
    {
      url: url,
      method: 'post',
      data
    }
  )
}

/**
 * 获取用户角色
 */
export function getUserRole(data) {
  const url = '/user/role'
  return request(
    {
      url: url,
      method: 'post',
      data
    }
  )
}

/**
 * 解绑角色
 */
export function unbindRole(data) {
  const url = '/user/unbind/role'
  return request(
    {
      url: url,
      method: 'post',
      data
    }
  )
}

/**
 * 绑定角色
 */
export function bindRole(data) {
  const url = '/user/bind/role'
  return request(
    {
      url: url,
      method: 'post',
      data
    }
  )
}

/**
* 修改用户密码
*/
export function userUpdatePwd(data) {
  const url = '/user/update/pwd'
  return request(
    {
      url: url,
      method: 'post',
      data
    }
  )
}
