import {getToken, removeToken, setToken} from "@/utils/auth";
import {getInfo, login} from "@/api/user";


const state = {
    token: getToken(),
    id: '',
    username: '',
    realName: '',
    nickName: '',
    avatar: '',
    // 预留角色
    role: '',
    // 预留权限
    permissions: ''
}

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_ID: (state, id) => {
        state.id = id
    },
    SET_USERNAME: (state, username) => {
        state.username = username
    },
    SET_REALNAME: (state, realName) => {
        state.realName = realName
    },
    SET_NICKNAME: (state, nickName) => {
        state.nickName = nickName
    },
    SET_AVATAR: (state, avatar) => {
        state.avatar = avatar
    },
}

const actions = {
    // 用户登录
    login({commit}, userInfo) {
        
        console.log(process.env.VUE_APP_BASE_API);
        const {username, password} = userInfo
        return new Promise((resolve, reject) => {
            login({ username: username.trim(), password: password }).then(response => {
                const token = response.data.tokenInfo.tokenValue
                commit('SET_TOKEN', token)
                setToken(token)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    // 获取用户信息
    getInfo({ commit }) {
        return new Promise((resolve, reject) => {
            // 这里param传个空对象过去
            getInfo({}).then(response => {
                const { data } = response
                if (!data) {
                    return reject('验证失败，请重新登录。')
                }
                // 拿到后端data数据后，赋值
                const {id, username, nickName, realName, avatar } = data
                commit('SET_ID', id)
                commit('SET_USERNAME', username)
                commit('SET_REALNAME', realName)
                commit('SET_NICKNAME', nickName)
                commit('SET_AVATAR', avatar)
                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // remove token
    resetToken({ commit }) {
        return new Promise(resolve => {
            removeToken() // must remove  token  first
            commit('SET_TOKEN', '')
            commit('SET_USERNAME', '')
            commit('SET_REALNAME', '')
            commit('SET_NICKNAME', '')
            commit('SET_AVATAR', '')
            resolve()
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
