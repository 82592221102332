const getters = {
    token: state => state.user.token,
    id: state => state.user.id,
    username: state => state.user.username,
    realName: state => state.user.realName,
    nickName: state => state.user.nickName,
    avatar: state => state.user.avatar,
    role: state => state.user.role,
    permissions: state => state.user.permissions,
}
export default getters
