import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

export const constantRoutes = [
    {
        path: '/login',
        component: () => import('@/views/login/index')
    },
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/home/index')
    },
    {
        path: '/release',
        name: 'release',
        component: () => import('@/views/release/index')
    },
    {
        path: '/release/upload',
        name: 'releaseUpload',
        component: () => import('@/views/release/upload')
    },
    {
        path: '/family',
        component: () => import('@/views/family/index')
    },
    { 
        path: '*', 
        component: () => import('@/views/404.vue')
    }
]

const createRouter = () => new VueRouter({
    mode: 'history',
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})

const router = createRouter();

export default router   