import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/style.css'
import './assets/css/reset.css'
import App from './App.vue'
import Vant from 'vant';
import { Lazyload } from 'vant';
import 'vant/lib/index.css';

import router from './router'
import store from './store'
import '@/assets/icons' // icon
import '@/permission'

Vue.config.productionTip = false

Vue.use(ElementUI);
Vue.use(Vant);
Vue.use(Lazyload);

new Vue({
  el: '#app',
  store,
  router,
  render: h => h(App)
})

window.addEventListener('popstate', function () {
  history.pushState(null, null, document.URL)
})